
import Vue from 'vue';
import VueHead from 'vue-head';

(function (w: any, d: any, s: any, l: any, i: any) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-MH476G8');

// only use in this component
Vue.use(VueHead);
import AltoleapSignUpWizard from '@/components/auth/SignUpWizard.vue';

export default Vue.extend({
  components: { AltoleapSignUpWizard },

  computed: {
    provider(): any {
      return this.$altoleapAccounts.authProvider;
    },
    companyName(): any {
      return this.$altoleapAccounts.config.companyName;
    },
    logo(): any {
      return this.$altoleapAccounts.config.logo;
    },

    privacyRoute() {
      return this.$altoleapAccounts.routes.privacyRoute;
    },
    termsRoute() {
      return this.$altoleapAccounts.routes.termsRoute;
    }
  }
});
